import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, NgForm } from '@angular/forms';

import { AppComponent } from './app.component';
import { ContestComponent } from './contest/contest.component';
import { QuestionComponent } from './contest/question/question.component';
import { EntryComponent } from './contest/entry/entry.component';
import { AppRoutingModule } from './app-routing.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { NgOptimizedImage } from "@angular/common";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressBarModule } from '@angular/material/progress-bar';

const maskConfig: Partial<IConfig> = {
  validation: true,
};

@NgModule({
  declarations: [
    AppComponent,
    ContestComponent,
    QuestionComponent,
    EntryComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    RecaptchaV3Module,
    NgOptimizedImage,
    FontAwesomeModule,
    GoogleMapsModule,
    GoogleTagManagerModule,
    NgxMaskModule.forRoot(maskConfig),
    BrowserAnimationsModule,
    MatProgressBarModule
    /*GoogleTagManagerModule.forRoot({
        id: 'GTM-K2B8XH',
    })*/
  ],
  providers: [
      {
        provide: RECAPTCHA_V3_SITE_KEY,
        useValue: environment.recaptcha.siteKey,
      },
      {
          provide: 'googleTagManagerId',
          useValue: 'GTM-K2B8XH',

      }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
