import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Question } from './question';
import { faPaperPlane, faArrowRightLong } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'question',
    templateUrl: './question.component.html',
    styleUrls: ['./question.component.scss']
})
export class QuestionComponent {
    @Input() questionList!: Question[];
    @Output() lastQuestion = new EventEmitter();
    question!: Question;
    questionIndex: number = 0;
    questionCounter: number = 0;
    entryAnswer: number = 0;
    showModal: boolean = false;
    showAnswer: boolean = false;
    faPaperPlane = faPaperPlane;
    faArrowRightLong = faArrowRightLong;

    lastQuestionClick() {
        /*console.log("Emitting to Contest Component!");*/
        this.lastQuestion.emit();
    }

    ngOnInit() {
        this.question = this.questionList[this.questionIndex];
        this.questionCounter = this.questionIndex + 1;
    }

    showFeedback() {
        let x = this.question.questionAnswers.find(x => x.questionAnswerId == this.entryAnswer);
        if(x) {
            if (x && x.correct) {
                this.showAnswer = true;
            }

            this.entryAnswer = 0;
            this.showModal = true;
        }
    }

    onSubmit() {
        /*console.log("Question List Length: " + this.questionList.length + "\n" + "Current Index: " + this.questionIndex);*/

        if (this.questionList.length === this.questionCounter) {
            /*console.log("Submit check.");*/
            this.lastQuestionClick();
        } else {
            this.questionIndex = this.questionIndex + 1;
            this.questionCounter = this.questionCounter + 1;
            this.question = this.questionList[this.questionIndex];
        }

        this.showModal = false;
        this.showAnswer = false;

    }


}
