import { Entry } from './entry';
import { Injectable, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ServiceBase } from '../../utilty/ServiceBase';
import { environment } from "../../../environments/environment";
import { GeocoderResponse } from './GeocodeResponse';
import { StoreLocation } from './storeLocation';
import { EntryResponse } from './entryResponse';

@Injectable({ providedIn: 'root' })

export class EntryService extends ServiceBase {
    constructor(private httpClient: HttpClient) {
        //geocoder.geoc
        super();
    }
    public createEntry(entry: any): Observable<EntryResponse> {
        return this.httpClient.post<EntryResponse>(
            this.baseUrl + '/Entry',
            JSON.stringify(entry),
            this.httpOptions 
        ).pipe(catchError(this.handleError));
    }

    getLocation(term: string): Observable<GeocoderResponse> {
        const url = `https://maps.google.com/maps/api/geocode/json?address=${term}&sensor=false&key=${environment.googleMapApiKey}`;
        return this.httpClient.get<GeocoderResponse>(url);
    }

    getStores(contestId: number, longitude: number, latitude: number): Observable<StoreLocation[]> {
        return this.httpClient.get<StoreLocation[]>(
            this.baseUrl + '/Store?contestId=' + contestId + '&longitude=' + longitude + '&latitude=' + latitude).pipe(catchError(this.handleError));
    }
}
