import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { faPaperPlane, faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { EntryService } from './entry.service';
import { Entry } from './entry';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { GeocoderResponse } from './GeocodeResponse'
import { StoreLocation } from './storeLocation';

@Component({
    selector: 'entry',
    templateUrl: './entry.component.html',
    styleUrls: ['./entry.component.scss'],
})
export class EntryComponent implements OnInit {
    @Input() contestId!: number;
    @Input() hasLocations!:boolean;
    @Input() contestMessage!: string;
    @ViewChild('entryForm') form!: NgForm;
    entry!: Entry;
    stores:StoreLocation[] = [];
    faPaperPlane = faPaperPlane;
    faLocationDot = faLocationDot;

    constructor(
        public entryService: EntryService,
        private recaptchaV3Service: ReCaptchaV3Service,
        private _activatedRoute: ActivatedRoute,
        private _router: Router
    ) {

    }

    ngOnInit() {

        this.entry = this._activatedRoute.snapshot.params['entry'];

        this.entry = {
            entryId: 0,
            emailaddress: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            contestId: this.contestId,
            firstName: "",
            lastName: "",
            optin: false,
            phone: "",
            state: "",
            invisibleToken: ""
        };
        if (this.hasLocations)
            this.getLocation();
    }

    getLocation() {
        var zipDefault ="";
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                if (position) {
                    var lat = position.coords.latitude;
                    var long = position.coords.longitude;
                    var latlng = new google.maps.LatLng(lat, long)
                    let geocoder = new google.maps.Geocoder();
                    geocoder.geocode({ 'location': latlng }, function (results, status) {
                        if (status == google.maps.GeocoderStatus.OK && results) {
                            for (var i = 0; i < results[0].address_components.length; i++) {
                                if (results[0].address_components[i].types[0] === "postal_code") {
                                    zipDefault = results[0].address_components[i].short_name;
                                }
                            }

                        }
                    }).then((success) => {
                        this.entry.zip = Number(zipDefault);
                        if(this.entry.zip != 0) {
                            this.getStores();
                        }
                      });
                }
            },
                (error) => console.log(error));
        } else {
            alert("Geolocation is not supported by this browser.");
        }

    }

    onSubmit(){
        console.log(this.form);

        // if form not valid set inputs
        if (!this.form.valid) {
            this.form.form.markAllAsTouched();
        } else {
            this.addEntry(this.entry);
        }
        // else run the add entry function
    };

    addEntry(data: any) {
        /*if(!this.entry.valid) {
            this.entry.markAllAsTouched();
        }*/
        // update the returnUrl to redirect the contest

        this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
          this.entry.invisibleToken = token;
          //TODO did not figure the subscribe out..
            this.entry.phone = this.entry.phone.replaceAll('-', '').replace('(', '').replace(')', '').replaceAll(' ','').replaceAll('.','');
            this.entryService.createEntry(this.entry).subscribe(response => {
                window.location.href = response.responseUrl;
            });
        });
    }

    getStores() {
        this.stores = [];
        this.entryService
            .getLocation(this.entry.zip!.toString())
            .subscribe(
                (response: GeocoderResponse) => {
                    console.log(response);
                    if (response.status === 'OK' && response.results?.length) {
                        const location = response.results[0];
                        const loc: any = location.geometry.location;
                        console.log(loc);
                        this.entryService.getStores(this.contestId, loc.lng, loc.lat)
                            .subscribe(x =>
                            {
                                console.log(x);
                                this.stores = x;
                                this.entry.storeLocationId = x[0].storeLocationId.toString();
                            });
                    }
                },
            );
    }
    update(e: any){
        this.entry.storeLocationId = e.target.value;
    }
}

