<h2 class="text-center sr-only">Create Entry</h2>
<form (ngSubmit)="onSubmit()" #entryForm="ngForm" class="grid narrow" *ngIf="entry">

    <div class="callout hide">
        {{ entryForm.value | json }}
        <hr>
        {{ entry | json }}
        <hr>
        {{entry.createDate}}
    </div>
    <div class="form-group">
        <p class="message" *ngIf="contestMessage">
            {{contestMessage}}
        </p>
        <p class="message" *ngIf="!contestMessage">
            Please enter your contact information to complete your contest entry. We use this information to contact our
            winner and mail the grand prize package.
        </p>
    </div>
    <div class="form-group" *ngIf="hasLocations">
        <!--*ngIf="entry.selectStore"-->
        <label for="zip" [class.is-invalid-label]="zip.invalid && zip.touched">Zip *</label>
        <div class="input-group">
            <input class="input-group-field form-control" id="zip" type="text" maxlength="5" #zip="ngModel"
                pattern="^\d{5}$" [class.is-invalid-input]="zip.invalid && zip.touched" [(ngModel)]="entry.zip"
                name="zip" required>
            <div class="input-group-button">
                <button class="button" type="button" (click)="getStores()">
                    <fa-icon [icon]="faLocationDot"></fa-icon>
                    <span class="button-text">Find Your Store</span>
                </button>
            </div>
        </div>
        <div *ngIf="zip.errors && (zip.valid || zip.touched)">
            <span class="form-error" *ngIf="zip.errors?.['required']">A zip code is required.</span>
            <span class="form-error" *ngIf="zip.errors?.['pattern']">Zip codes must be 5 digits.</span>
        </div>
    </div>
    <div class="form-group" *ngIf="hasLocations">
        <label for="fareway" [class.is-invalid-label]="store.invalid && store.touched">Fareway Location</label>
        <select [ngModel]=entry.storeLocationId (change)="update($event)" id="fareway" class="form-control"
            #store="ngModel" [class.is-invalid-input]="store.invalid && store.touched" name="store" required>
            <option *ngFor="let store of this.stores" [value]="store.storeLocationId">{{store.address}} {{store.city}},
                {{store.state}} {{store.zipCode}}</option>
        </select>
        <span class="form-error" [class.hide]="!store.invalid || store.untouched">A store location is required.</span>
    </div>
    <hr *ngIf="hasLocations">
    <div class="form-group span-6">
        <label for="firstName" [class.is-invalid-label]="firstName.invalid && firstName.touched">First Name *</label>
        <input id="firstName" type="text" #firstName="ngModel"
            [class.is-invalid-input]="firstName.invalid && firstName.touched" [(ngModel)]="entry.firstName"
            name="firstName" class="form-control" required pattern="^[^0-9]*$">
        <div *ngIf="firstName.invalid && firstName.touched">
            <span class="form-error" *ngIf="firstName.errors?.['required']">First Name is required.</span>
            <span class="form-error" *ngIf="firstName.errors?.['pattern']">First Name cannot contain a number.</span>
        </div>
    </div>
    <div class="form-group span-6">
        <label for="lastName" [class.is-invalid-label]="lastName.invalid && lastName.touched">Last Name *</label>
        <input id="lastName" type="text" #lastName="ngModel"
            [class.is-invalid-input]="lastName.invalid && lastName.touched" [(ngModel)]="entry.lastName" name="lastName"
            class="form-control" required pattern="^[^0-9]*$">
        <span class="form-error" [class.hide]="lastName.valid || lastName.untouched"></span>
        <div *ngIf="lastName.invalid && lastName.touched">
            <span class="form-error" *ngIf="lastName.errors?.['required']">Last Name is required.</span>
            <span class="form-error" *ngIf="lastName.errors?.['pattern']">Last Name cannot contain a number.</span>
        </div>
    </div>
    <div class="form-group">
        <label for="emailAddress" [class.is-invalid-label]="email.invalid && email.touched">Email *</label>
        <input id="emailAddress" type="email" pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$" #email="ngModel"
            [class.is-invalid-input]="email.invalid && email.touched" [(ngModel)]="entry.emailaddress"
            name="emailaddress" class="form-control" required>
        <div *ngIf="email.errors && (email.valid || email.touched)">
            <span class="form-error" *ngIf="email.errors?.['required']">An email is required.</span>
            <span class="form-error" *ngIf="email.errors?.['pattern']">Not a valid email.</span>
        </div>
        <span class="form-error" [class.hide]="email.valid || email.untouched"></span>
    </div>
    <div class="form-group checkbox">
        <input id="optIn" type="checkbox" #optin="ngModel" [class.is-invalid-input]="optin.invalid && optin.touched"
            [(ngModel)]="entry.optin" name="optin" class="form-control" />
        <label for="optIn">Email me more about food and farming, through the free Iowa Dish e-newsletter.
            <small>(OPTIONAL)</small></label>
    </div>
    <div class="form-group">
        <label for="phone" [class.is-invalid-label]="phone.invalid && phone.touched">Phone *</label>
        <input id="phone" type="tel" maxlength="14" mask="000-000-0000" placeholder="555-555-5555" 
            #phone="ngModel" [class.is-invalid-input]="phone.invalid && phone.touched" [(ngModel)]="entry.phone"
            name="phone" class="form-control" required>
        <div *ngIf="phone.errors && (phone.valid || phone.touched)">
            <span class="form-error" *ngIf="phone.errors?.['required']">A phone number is required.</span>
            <span class="form-error" *ngIf="phone.errors?.['mask']">Not a valid phone number (555-555-5555).</span>
        </div>
    </div>
    <div class="form-group">
        <label for="address1" [class.is-invalid-label]="address1.invalid && address1.touched">Address 1 *</label>
        <input id="address1" type="text" #address1="ngModel"
            [class.is-invalid-input]="address1.invalid && address1.touched" [(ngModel)]="entry.addressLine1"
            name="addressLine1" class="form-control" required>
        <span class="form-error" [class.hide]="address1.valid || address1.untouched">An Address is required.</span>
    </div>
    <div class="form-group">
        <label for="address2">Address 2 <small>(OPTIONAL)</small></label>
        <input id="address2" type="text" #address2="ngModel"
            [class.is-invalid-input]="address2.invalid && address2.touched" [(ngModel)]="entry.addressLine2"
            name="addressLine2" class="form-control">
    </div>
    <div class="form-group span-7">
        <label for="city" [class.is-invalid-label]="city.invalid && city.touched">City *</label>
        <input id="city" type="text" #city="ngModel" [class.is-invalid-input]="city.invalid && city.touched"
            [(ngModel)]="entry.city" name="city" class="form-control" required>
        <span class="form-error" [class.hide]="city.valid || city.untouched">A city is required.</span>
    </div>
    <div class="form-group span-3">
        <label for="state" [class.is-invalid-label]="state.invalid && state.touched">State *</label>
        <input id="state" type="text" #state="ngModel" [class.is-invalid-input]="state.invalid && state.touched"
            [(ngModel)]="entry.state" name="state" class="form-control" required>
        <span class="form-error" [class.hide]="state.valid || state.untouched">A state is required.</span>
    </div>
    <div class="form-group span-2">
        <label for="postalCode" [class.is-invalid-label]="postal.invalid && postal.touched">Zip *</label>
        <input id="postalCode" type="text" maxlength="5" pattern="^\d{5}$" #postal="ngModel"
            [class.is-invalid-input]="postal.invalid && postal.touched" [(ngModel)]="entry.zip" name="zip"
            class="form-control" required>
        <div *ngIf="postal.errors && (postal.valid || postal.touched)">
            <span class="form-error" *ngIf="postal.errors?.['required']">A zip code is required.</span>
            <span class="form-error" *ngIf="postal.errors?.['pattern']">Zip codes must be 5 digits.</span>
        </div>
    </div>
    <div class="form-group submit">
        <button class="button" type="submit">
            <fa-icon class="button-icon" [icon]="faPaperPlane"></fa-icon>
            <span class="button-text">Submit Entry</span>
        </button>
        <span class="help-text no-margin">* All fields are required unless indicated otherwise.</span>
    </div>
</form>
