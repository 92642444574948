import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Component, Input,OnInit } from '@angular/core';
import { Contest } from './contest';
import { ContestService } from './contest.service';
import { Observable, of } from 'rxjs';
import { Title } from "@angular/platform-browser";


@Component({
  selector: 'contest',
  templateUrl: './contest.component.html',
  styleUrls: ['./contest.component.scss']
})

export class ContestComponent {
    @Input() showEntry!: boolean;
    urlPath!: string;
    contestData!: Contest;
    displayDescription: boolean = false;
    contestOver: boolean = false;
    contestRunning: boolean = false;
    questionsComplete: boolean = false;

    constructor(private router: Router, private route: ActivatedRoute, public contestService: ContestService, private titleService: Title) { }

    ngOnInit() {
        this.urlPath = this.route.snapshot.params['urlPath'];
        this.setData();
    }

    onDoneQuestions() {
        this.questionsComplete = true;
        this.router.navigate([this.urlPath,'entry']);
    }
    onOutletLoaded(entry: { contestId: number; hasLocations: boolean }) {
        entry.contestId = this.contestData.contestId;
        entry.hasLocations = this.contestData.hasStoreLocations;
    }

    setData() {
        this.contestService.getContest(this.urlPath).subscribe(data => {
            this.contestData = data;
            let datenow = new Date();
            let datestart = new Date(this.contestData.startDate);
            let dateend = new Date(this.contestData.endDate);

            if (datestart <= datenow && datenow <= dateend) {
                this.contestRunning = true;
            } else if (datenow <= datestart) {
                this.displayDescription = true;
            } else {
                this.contestOver = true;
            }
            this.titleService.setTitle(this.contestData.contestName);

            const body = document.body;
            let bodyImageURL = "url('"+this.contestData.backgroundImageUrl+"')";
            body.style.setProperty("--body-bg-image", bodyImageURL);
        });
    }

}
