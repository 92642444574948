
<div class="question" *ngIf="question">
    <div>
        <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
        <p style="text-align: right;">Question {{this.questionCounter}} of {{this.questionList.length}}</p>
      
    </div>
    <h2>
        <span>{{questionCounter}})</span>
        <span style="text-wrap: pretty;" [innerHTML]='question.questionText'></span>
    </h2>

    <fieldset [attr.data-question-id]="question.questionId">
        <legend class="sr-only">Select one of the following options:</legend>

        <div class="form-group radio" *ngFor="let questionAnswer of question.questionAnswers">
            <input
                class="radio-custom"
                type="radio"
                name="question{{question.questionId}}_answer"
                id="question{{question.questionId}}_answer{{questionAnswer.questionAnswerId}}"
                value="{{questionAnswer.questionAnswerId}}"
                [(ngModel)]="entryAnswer">

            <label class="radio-custom-label"
                   for="question{{question.questionId}}_answer{{questionAnswer.questionAnswerId}}">
                <!--<fa-icon class="button-icon" [icon]="faCheck"></fa-icon>-->
                {{questionAnswer.questionAnswerText}}
            </label>

            <!--<span class="help-text">Current Selection: {{entryAnswer}}<span></span></span>
            <span class="help-text">HINT: {{questionAnswer.correct}}</span>-->
        </div>

    </fieldset>

    <div class="form-group">
        <button class="button" type="button" (click)="showFeedback()" [disabled]="entryAnswer == 0">
            <fa-icon class="button-icon" [icon]="faPaperPlane"></fa-icon>
            <span class="button-text">Submit</span>
        </button>
        <span class="help-text no-margin" *ngIf="entryAnswer == 0">Select an option to continue.</span>
    </div>
</div>


<div class="modal-container question-feedback" *ngIf="showModal">
    <div class="modal">
        <div class="modal-body">
            <div class="feedback" data-feedback-type="correct" *ngIf="showAnswer">
                <h3>{{question.correctFeedbackHeading}}</h3>
                <p>{{question.correctFeedbackDescription}}</p>
                <cite *ngIf="question.correctFeedbackSource">Source: {{question.correctFeedbackSource}}</cite>
            </div>
            <div class="feedback" data-feedback-type="incorrect" *ngIf="!showAnswer">
                <h3>{{question.inCorrectFeedbackHeading}}</h3>
                <p>{{question.inCorrectFeedbackDescription}}</p>
                <cite *ngIf="question.inCorrectFeedbackSource">Source: {{question.inCorrectFeedbackSource}}</cite>
            </div>
        </div>
        <div class="modal-footer">
            <div class="form-group">
                <button class="button" type="button" (click)="onSubmit()">
                    <span class="button-text">Next</span>
                    <fa-icon class="button-icon" [icon]="faArrowRightLong"></fa-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="modal-background"></div>
</div>
