import { Injectable, ErrorHandler } from '@angular/core';
import { LogService } from './log.service'

@Injectable()
export class ErrorService extends ErrorHandler {
  constructor(private logService: LogService) {
    super();
  }

  override handleError(error: Error)
  {
    this.logService.logError(error);
  }
}
