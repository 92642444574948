import { Injectable } from '@angular/core';
import { ApplicationInsights, eSeverityLevel } from '@microsoft/applicationinsights-web'
import { environment } from '../../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class LogService {
  appInsights: ApplicationInsights;
  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsightsInstrumentationKey,
        enableAutoRouteTracking: true //log all route changes
      }
    });
    this.appInsights.loadAppInsights();
  }
  logError(error: Error) {
    //log error to console
    console.error(error);

    //log error to app insights
    this.appInsights.trackException({ exception: error, severityLevel: eSeverityLevel.Error});
  }
}
