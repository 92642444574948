import { NgModule, ErrorHandler } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContestComponent } from './contest/contest.component';
import { EntryComponent } from './contest/entry/entry.component';
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { ErrorService } from './service/error.service'

const routes: Routes = [
    /*{
        path: ':urlPath/entry',
        component: EntryComponent,
        *//*title: 'Entry Form | Contest Name' // Figure out way to reduce visibility/load time.*//*
    },*/
    {
        path: ':urlPath',
        component: ContestComponent,
        children: [
            {
                path: 'entry', // child route path
                component: EntryComponent, // child route component that the router renders
            }
        ],
        /*title: 'Contest Name' // Figure out way to reduce visibility/load time.*/
    },
    /*{
        path: '',
        redirectTo: ':urlPath',
        component: ContestComponent,
        pathMatch: 'full'
    },*/
    {
        path: '**',
        component: PageNotFoundComponent,
        title: 'Page Not Found'
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: ErrorHandler, useClass: ErrorService
    }
  ]
})
export class AppRoutingModule { }
