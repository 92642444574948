<main class="main" id="body-container" *ngIf="contestData">
    <div class="contest" id="entryContainer">
        <div class="contest-header">
            <h1 class="sr-only">{{contestData.contestName}}</h1>
            <img [src]="contestData.headerImageUrl" [alt]="contestData.contestName" width="705" height="193">
        </div>
        <div class="contest-content">
            <!--@if(DateTime.Now < ViewBag.startTime && !_configRepository.getAll().First().showQuiz)
            {-->
            <div class="contest-block" *ngIf="displayDescription">
                <p class="h4" [innerHTML]='contestData.contestDescription'></p>
            </div>

            <!--Contest Over-->
            <!--@if (DateTime.Now >= ViewBag.endTime && !_configRepository.getAll().First().showQuiz)
            {-->
            <div class="contest-block" *ngIf="contestOver">
                <p class="h4" [innerHTML]='contestData.contestCloseDescription'></p>
            </div>
            <!--@* contest is running *@
            @if ((DateTime.Now >= ViewBag.startTime && DateTime.Now < ViewBag.endTime) || _configRepository.getAll().First().showQuiz)
            {-->
            <!--<script type="text/javascript" src="@Url.Content(" ~ /Anf/js/app.js")"></script>
            <div class="quiz-container" data-ng-app="quizApp" data-ng-show="true" data-ng-init="start()">
            <quiz />
            </div>-->
            <div class="contest-block" *ngIf="contestRunning">
                <question *ngIf="!questionsComplete" class="quiz-questions" [questionList]="contestData.questionList" (lastQuestion)="onDoneQuestions()"></question>
                <router-outlet *ngIf="questionsComplete" (lastQuestion)="onDoneQuestions()" (activate)="onOutletLoaded($event)" ></router-outlet>
            </div>
        </div>
    </div>
    <div class="contest-banner">
        <img [src]="contestData.companyImageUrl" alt="Iowa Farm Bureau Federation" loading="lazy" decoding="async">
        <img *ngIf="contestData.footerImageUrl" [src]="contestData.footerImageUrl" alt="Contest sponsor logo." loading="lazy" decoding="async">
    </div>
</main>
