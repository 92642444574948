import { Contest } from './contest';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from '../utilty/ServiceBase';
import { Observable, of } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })

export class ContestService extends ServiceBase {

  constructor(private httpClient: HttpClient) {
    super();
  }
  public getContest(urlName: string){
    return this.httpClient.get<Contest>(this.baseUrl + '/Contest/GetContestQuestionsbyUrlName?urlName=' + urlName).pipe(retry(1), catchError(this.handleError));
    
  }
}
