import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { faArrowRightLong } from '@fortawesome/pro-solid-svg-icons';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Router, Event, NavigationEnd } from "@angular/router";
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    year = new Date().getFullYear();
    faArrowRightLong = faArrowRightLong;
    /*constructor(private readonly gtmService: GoogleTagManagerService) { }*/
    constructor(private router: Router, private gtmService: GoogleTagManagerService) {
        const navEndEvents = this.router.events.pipe(
            filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
        );
        navEndEvents.subscribe((event: NavigationEnd) => {
            this.gtmService.pushTag({ event: 'page', pageName: event.urlAfterRedirects });
        });
    }
    ngOnInit() {
        this.gtmService.addGtmToDom();
    }
}
